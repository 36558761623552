
.line{
    display: block;
    width: 1px;
    height: 12px;
    background-color: var(--border-color);
}
::v-deep .v-btn.v-btn--text{
    height: auto;
    font-size: 1.4rem;
    padding: 0;
    min-width: 0;
}
